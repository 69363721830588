var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("today")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Datepicker - "),
                  _c("small", [_vm._v("labeled")]),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c(
                  "md-datepicker",
                  {
                    model: {
                      value: _vm.selectedLabeled,
                      callback: function ($$v) {
                        _vm.selectedLabeled = $$v
                      },
                      expression: "selectedLabeled",
                    },
                  },
                  [_c("label", [_vm._v("Select date")])]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("today")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Datepicker - "),
                  _c("small", [_vm._v("date selected")]),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-datepicker", {
                  model: {
                    value: _vm.selectedDate,
                    callback: function ($$v) {
                      _vm.selectedDate = $$v
                    },
                    expression: "selectedDate",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-small-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("today")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Datepicker - "),
                  _c("small", [_vm._v("close on select")]),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-datepicker", {
                  attrs: { "md-immediately": "" },
                  model: {
                    value: _vm.selectedClose,
                    callback: function ($$v) {
                      _vm.selectedClose = $$v
                    },
                    expression: "selectedClose",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Switches"),
                    ]),
                    _c(
                      "md-switch",
                      {
                        model: {
                          value: _vm.switch1,
                          callback: function ($$v) {
                            _vm.switch1 = $$v
                          },
                          expression: "switch1",
                        },
                      },
                      [_vm._v("Toogle is on")]
                    ),
                    _c(
                      "md-switch",
                      {
                        model: {
                          value: _vm.switch2,
                          callback: function ($$v) {
                            _vm.switch2 = $$v
                          },
                          expression: "switch2",
                        },
                      },
                      [_vm._v("Toogle is off")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Customisable Select"),
                    ]),
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        { staticClass: "md-layout-item" },
                        [
                          _c(
                            "md-field",
                            [
                              _c("label", { attrs: { for: "movie" } }, [
                                _vm._v("Movie"),
                              ]),
                              _c(
                                "md-select",
                                {
                                  attrs: { name: "movie", id: "movie" },
                                  model: {
                                    value: _vm.movie,
                                    callback: function ($$v) {
                                      _vm.movie = $$v
                                    },
                                    expression: "movie",
                                  },
                                },
                                [
                                  _c(
                                    "md-option",
                                    { attrs: { value: "fight-club" } },
                                    [_vm._v("Fight Club")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfather" } },
                                    [_vm._v("Godfather")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfather-ii" } },
                                    [_vm._v("Godfather II")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfather-iii" } },
                                    [_vm._v("Godfather III")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfellas" } },
                                    [_vm._v("Godfellas")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "pulp-fiction" } },
                                    [_vm._v("Pulp Fiction")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "scarface" } },
                                    [_vm._v("Scarface")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "md-layout-item" },
                        [
                          _c(
                            "md-field",
                            [
                              _c("label", { attrs: { for: "movies" } }, [
                                _vm._v("Movies"),
                              ]),
                              _c(
                                "md-select",
                                {
                                  attrs: {
                                    name: "movies",
                                    id: "movies",
                                    multiple: "",
                                  },
                                  model: {
                                    value: _vm.selectedMovies,
                                    callback: function ($$v) {
                                      _vm.selectedMovies = $$v
                                    },
                                    expression: "selectedMovies",
                                  },
                                },
                                [
                                  _c(
                                    "md-option",
                                    { attrs: { value: "fight-club" } },
                                    [_vm._v("Fight Club")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfather" } },
                                    [_vm._v("Godfather")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfather-ii" } },
                                    [_vm._v("Godfather II")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfather-iii" } },
                                    [_vm._v("Godfather III")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "godfellas" } },
                                    [_vm._v("Godfellas")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "pulp-fiction" } },
                                    [_vm._v("Pulp Fiction")]
                                  ),
                                  _c(
                                    "md-option",
                                    { attrs: { value: "scarface" } },
                                    [_vm._v("Scarface")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [_vm._v("Tags")]),
                    _c("md-chips", {
                      staticClass: "md-primary",
                      attrs: { "md-placeholder": "Add city..." },
                      model: {
                        value: _vm.fruits,
                        callback: function ($$v) {
                          _vm.fruits = $$v
                        },
                        expression: "fruits",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Dropdown & Dropup"),
                    ]),
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-50 md-xsmall-size-100",
                        },
                        [
                          _c(
                            "drop-down",
                            { attrs: { direction: "down", multiLevel: "" } },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-button md-success md-round md-block dropdown-toggle",
                                  attrs: {
                                    slot: "title",
                                    "data-toggle": "dropdown",
                                  },
                                  slot: "title",
                                },
                                [_vm._v(" Multilevel Dropdown ")]
                              ),
                              _c(
                                "ul",
                                {
                                  staticClass: "dropdown-menu",
                                  class: {
                                    "dropdown-menu-right": _vm.responsive,
                                  },
                                },
                                [
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("Action"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("Another Action"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-toggle",
                                        class: { open: _vm.multiLevel },
                                        on: { click: _vm.toggleMultiLevel },
                                      },
                                      [_vm._v("Submenu")]
                                    ),
                                    _c("ul", { staticClass: "dropdown-menu" }, [
                                      _c("li", [
                                        _c("a", { attrs: { href: "#" } }, [
                                          _vm._v("Submenu action"),
                                        ]),
                                      ]),
                                      _c("li", [
                                        _c("a", { attrs: { href: "#" } }, [
                                          _vm._v("Submenu action"),
                                        ]),
                                      ]),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-toggle",
                                            class: { open: _vm.multiLevel2 },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleMultiLevel2()
                                              },
                                            },
                                          },
                                          [_vm._v("Subsubmenu")]
                                        ),
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "dropdown-menu",
                                            class: {
                                              "dropdown-menu-right":
                                                _vm.responsive,
                                            },
                                          },
                                          [
                                            _c("li", [
                                              _c(
                                                "a",
                                                { attrs: { href: "#" } },
                                                [_vm._v("Subsubmenu action 1")]
                                              ),
                                            ]),
                                            _c("li", [
                                              _c(
                                                "a",
                                                { attrs: { href: "#" } },
                                                [_vm._v("Subsubmenu action 2")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("li", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-toggle",
                                            class: { open: _vm.multiLevel3 },
                                            on: {
                                              click: _vm.toggleMultiLevel3,
                                            },
                                          },
                                          [_vm._v("Second Subsubmenu")]
                                        ),
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "dropdown-menu",
                                            class: {
                                              "dropdown-menu-right":
                                                _vm.responsive,
                                            },
                                          },
                                          [
                                            _c("li", [
                                              _c(
                                                "a",
                                                { attrs: { href: "#" } },
                                                [_vm._v("Subsubmenu action 1")]
                                              ),
                                            ]),
                                            _c("li", [
                                              _c(
                                                "a",
                                                { attrs: { href: "#" } },
                                                [_vm._v("Subsubmenu action 2")]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-50 md-xsmall-size-100",
                        },
                        [
                          _c(
                            "drop-down",
                            { attrs: { direction: "down" } },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-button md-success md-round md-block dropdown-toggle",
                                  attrs: {
                                    slot: "title",
                                    "data-toggle": "dropdown",
                                  },
                                  slot: "title",
                                },
                                [_vm._v(" Dropdown ")]
                              ),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right",
                                },
                                [
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v(
                                        "Mike John responded to your email"
                                      ),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("You have 5 new tasks"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("You're now friend with Andrew"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("Another Notification"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("Another One"),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-50 mx-auto md-xsmall-size-100",
                        },
                        [
                          _c(
                            "drop-down",
                            { attrs: { direction: "up" } },
                            [
                              _c(
                                "md-button",
                                {
                                  staticClass:
                                    "md-button md-success md-round md-block dropdown-toggle",
                                  attrs: {
                                    slot: "title",
                                    "data-toggle": "dropdown",
                                  },
                                  slot: "title",
                                },
                                [_vm._v(" Dropup ")]
                              ),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "dropdown-menu dropdown-menu-right",
                                },
                                [
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v(
                                        "Mike John responded to your email"
                                      ),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("You have 5 new tasks"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("You're now friend with Andrew"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("Another Notification"),
                                    ]),
                                  ]),
                                  _c("li", [
                                    _c("a", { attrs: { href: "#" } }, [
                                      _vm._v("Another One"),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Progress Bar"),
                    ]),
                    _c("md-progress-bar", {
                      staticClass: "md-primary",
                      attrs: { "md-value": _vm.amount },
                    }),
                    _c("md-progress-bar", {
                      staticClass: "md-info",
                      attrs: { "md-value": _vm.amount2 },
                    }),
                    _c("md-progress-bar", {
                      staticClass: "md-warning",
                      attrs: {
                        "md-mode": "buffer",
                        "md-value": _vm.buffer,
                        "md-buffer": _vm.buffer,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Sliders"),
                    ]),
                    _c("slider", {
                      model: {
                        value: _vm.sliders.simple,
                        callback: function ($$v) {
                          _vm.$set(_vm.sliders, "simple", $$v)
                        },
                        expression: "sliders.simple",
                      },
                    }),
                    _c("br"),
                    _c("slider", {
                      attrs: { type: "info", connect: true },
                      model: {
                        value: _vm.sliders.rangeSlider,
                        callback: function ($$v) {
                          _vm.$set(_vm.sliders, "rangeSlider", $$v)
                        },
                        expression: "sliders.rangeSlider",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-30 md-xsmall-size-100",
                  },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Regular Image"),
                    ]),
                    _c("div", { staticClass: "file-input" }, [
                      !_vm.imageRegular
                        ? _c("div", [
                            _c("div", { staticClass: "image-container" }, [
                              _c("img", {
                                attrs: { src: _vm.regularImg, title: "" },
                              }),
                            ]),
                          ])
                        : _c("div", { staticClass: "image-container" }, [
                            _c("img", { attrs: { src: _vm.imageRegular } }),
                          ]),
                      _c(
                        "div",
                        { staticClass: "button-container" },
                        [
                          _vm.imageRegular
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-danger md-round",
                                  on: { click: _vm.removeImage },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-times" }),
                                  _vm._v("Remove"),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "md-button",
                            { staticClass: "md-success md-round md-fileinput" },
                            [
                              !_vm.imageRegular
                                ? [_vm._v("Select image")]
                                : [_vm._v("Change")],
                              _c("input", {
                                attrs: { type: "file" },
                                on: { change: _vm.onFileChange },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "md-layout-item md-size-25" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Avatar Image"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "file-input img-circle" },
                    [
                      !_vm.imageCircle
                        ? [
                            _c("div", { staticClass: "image-container" }, [
                              _c("img", {
                                attrs: { src: _vm.avatarImg, title: "" },
                              }),
                            ]),
                          ]
                        : _c("div", { staticClass: "image-container" }, [
                            _c("img", { attrs: { src: _vm.imageCircle } }),
                          ]),
                      _c(
                        "div",
                        { staticClass: "button-container" },
                        [
                          _vm.imageCircle
                            ? _c(
                                "md-button",
                                {
                                  staticClass: "md-danger md-round",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeImage("circle")
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fa fa-times" }),
                                  _vm._v("Remove"),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "md-button",
                            { staticClass: "md-success md-round md-fileinput" },
                            [
                              !_vm.imageCircle
                                ? [_vm._v("Add Photo")]
                                : [_vm._v("Change")],
                              _c("input", {
                                attrs: { type: "file", name: "circle" },
                                on: { change: _vm.onFileChange },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }